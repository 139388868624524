import React, { Component } from "react";
import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import PropTypes from "prop-types";
import SVGIcon from "../components/SVGIcon";
import Main from "../components/Main";
import Layout from "../components/Layout";
import ContactRow from "../components/ContactRow";
import ContentRow from "../components/ContentRow";
import ContactForm from "../components/ContactForm";
import Message from "../components/Message";
import sendMessage from "../components/ContactService";
import SEO from "../components/seo";
import StyledContactInfo from "../components/templateStyles/StyledContactInfo";

class Contact extends Component {
	constructor(props) {
		super(props);
		this.changeForm = this.changeForm.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.messageDelay = this.messageDelay.bind(this);
	}

	state = {
		formValues: {
			name: "",
			email: "",
			message: "",
			gdpr: false
		},
		loading: false,
		showMessage: false,
		message: null,
		messageType: "success"
	};

	changeForm(e) {
		const { name } = e.target;
		const { value } = e.target;

		const { formValues } = this.state;
		formValues[name] = value;

		this.setState({ formValues });
	}

	submitForm() {
		this.setState({ loading: true });
		sendMessage(this.state.formValues)
			.then(response => {
				this.setState({
					showMessage: true,
					message: this.props.pageContext.MESSAGE_SUCCESS,
					loading: false,
					messageType: "success",
					formValues: {
						name: "",
						email: "",
						message: ""
					}
				});
			})
			.then(() => {
				setInterval(() => this.messageDelay(), 3000);
			})
			.catch(error => {
				this.setState({
					loading: false,
					showMessage: true,
					message: this.props.pageContext.MESSAGE_FAILED,
					messageType: "error"
				});
			})
			.then(() => {
				setInterval(() => this.messageDelay(), 3000);
			});
	}

	messageDelay() {
		this.setState({
			showMessage: false
		});
	}

	render() {
		return (
			<Layout pageContext={this.props.pageContext}>
				<Main className="page-container">
					<SEO
						title={this.props.pageContext.CONTACT_TITLE}
						description={this.props.pageContext.CONTACT_DESCRIPTION}
						lang={this.props.pageContext.lang}
					/>
					<div style={{ height: "80px" }} />
					<ContentRow background="white" rowContentClass="no-padding">
						<div className="menu-row" />
					</ContentRow>
					{this.state.showMessage && (
						<Message
							type={this.state.messageType}
							message={this.state.message}
						/>
					)}
					<ContentRow background="white" rowContentClass="no-padding">
						<StyledContactInfo>
							<div className="contact_address">
								<div className="headline">
									<h1>{this.props.pageContext.CONTACT_ADDRESS_HEADLINE}</h1>
								</div>
								<div className="content">
									{this.props.pageContext.CONTACT_ADDRESSES.map(item => (
										<p style={{ paddingBottom: "20px" }}>
											<strong dangerouslySetInnerHTML={{ __html: item.BOLD }} />
											<br />
											<span
												dangerouslySetInnerHTML={{ __html: item.CONTENT }}
											/>
											<br />
											<a href={item.MAP_LINK.URL} data-testid={item.MAP_LINK.TEST_ID} target="blank">
												{item.MAP_LINK.LABEL}
											</a>
										</p>
									))}
								</div>
							</div>
							<BackgroundImage
								fadeIn={false}
								className="contact_photo"
								style={{ backgroundSize: "contain" }}
								fluid={this.props.data.contact_photo.childImageSharp.fluid}
							/>
							<div className="contact_media">
								<div className="headline">
									<h2>{this.props.pageContext.CONTACT_MEDIA_HEADLINE}</h2>
								</div>
								<div className="content">
									<div className="media-icon black">
										<a
											href="https://www.facebook.com/synetechsro/"
											target="blank"
											aria-label="Navštivte náš Facebook."
											rel="noopener noreferrer"
										>
											<SVGIcon name="faFacebook" className="black" />
										</a>
									</div>
									<div className="media-icon black">
										<a
											href="https://twitter.com/synetech_cz"
											target="blank"
											aria-label="Navštivte náš Twitter."
											rel="noopener noreferrer"
										>
											<SVGIcon name="faTwitter" className="black" />
										</a>
									</div>
									<div className="media-icon black">
										<a
											href="https://www.instagram.com/synetech/"
											target="blank"
											aria-label="Navštivte náš Instagram."
											rel="noopener noreferrer"
										>
											<SVGIcon name="faInstagram" className="black" />
										</a>
									</div>
								</div>
							</div>
						</StyledContactInfo>
					</ContentRow>
					<ContentRow background="green-light" rowContentClass="no-padding">
						<div className="anchor" id="contact-form" />
						<ContactForm
							pageContext={this.props.pageContext}
							lang={this.props.lang}
							loading={this.state.loading}
							formValues={this.state.formValues}
							onChangeForm={this.changeForm}
							onSubmit={this.submitForm}
						/>
					</ContentRow>
					<ContactRow
						lang={this.props.lang}
						pageContext={this.props.pageContext}
					/>
				</Main>
			</Layout>
		);
	}
}

Contact.propTypes = {
	pageContext: PropTypes.objectOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.bool,
			PropTypes.object,
			PropTypes.array
		])
	).isRequired
};
export default Contact;

export const query = graphql`
	query ContactQuery {
		contact_photo: file(relativePath: { eq: "contact-photo.png" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
	}
`;
