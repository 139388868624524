import React from "react";

export default function Spinner(props) {
	const { containerClass = "loader-container", loaderClass = "loader" } = props;

	return (
		<div className={containerClass}>
			<div className={loaderClass} />
		</div>
	);
}
