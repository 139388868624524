import React, { Component } from "react";
import PropTypes from "prop-types";
import StyledForm from "./style";

export default class Form extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.validateForm = this.validateForm.bind(this);
	}

	static propTypes = {
		loading: PropTypes.bool
	};

	state = {
		// isValid: false
	};

	validateForm() {
		let isValid = true;

		for (let elem = 0; elem < this.formEl.length; elem++) {
			const currentElement = this.formEl[elem];

			if (
				currentElement.type === "submit" ||
				currentElement.type === "button"
			) {
				continue;
			}

			// reset custom error (pokud byl pri predchozim ulozeni nastaven)
			currentElement.setCustomValidity("");

			/*
            console.log('current element:'+ currentElement.type + ' ' + currentElement.required + ' value:' + currentElement.value)
            console.log(currentElement.validity)
            */

			if (!currentElement.checkValidity()) {
				const errorMessage = "Invalid value";
				currentElement.setCustomValidity(errorMessage);
				currentElement.parentNode.querySelector(".error-message").className =
					"error-message invalid";
				isValid = false;
			} else {
				currentElement.parentNode.querySelector(".error-message").className =
					"error-message";
			}
		}

		return isValid;
	}

	handleSubmit(e) {
		e.preventDefault();

		if (this.validateForm()) {
			this.props.onSubmit();
		}
	}

	render() {
		return (
			<StyledForm>
				<form
					autoComplete="off"
					ref={form => {
						this.formEl = form;
					}}
					onSubmit={this.handleSubmit}
					noValidate
				>
					{this.props.children}
				</form>
			</StyledForm>
		);
	}
}
