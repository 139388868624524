import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Form, Textarea, TextInput } from "../forms";
import StyledContactForm from "./style";

function ContactForm({
	formValues,
	onChangeForm,
	onSubmit,
	pageContext,
	loading
}) {
	function handleGdprCheckbox(e) {
		e.target = {
			name: "gdpr",
			value: !formValues.gdpr
		};
		onChangeForm(e);
	}

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		onChangeForm({
			target: {
				name: "discriminant",
				value: urlSearchParams.has("formtype")
					? urlSearchParams.get("formtype")
					: "sales"
			}
		})
	}, []);

	return (
		<StyledContactForm>
			<Form onSubmit={onSubmit}>
				<div className="contact_form-section">
					<div className="contact_form-header">
						<h2>{pageContext.CONTACT_FORM_HEADLINE}</h2>
					</div>
					<div className="contact_form-name">
						<TextInput
							name="name"
							value={formValues.name}
							label={pageContext.CONTACT_FORM_NAME}
							required
							errorMessage={pageContext.CONTACT_FORM_ERROR_NAME}
							onChange={onChangeForm}
						/>
					</div>
					<div className="contact_form-email">
						<TextInput
							name="email"
							value={formValues.email}
							label={pageContext.CONTACT_FORM_EMAIL}
							type="email"
							required
							errorMessage={pageContext.CONTACT_FORM_ERROR_EMAIL}
							onChange={onChangeForm}
						/>
					</div>
					<div className="contact_form-note">
						<Textarea
							rows={10}
							name="message"
							value={formValues.message}
							label={pageContext.CONTACT_FORM_NOTE}
							required={false}
							onChange={onChangeForm}
						/>
					</div>
					<div className="contact_form-gdpr">
						<Checkbox
							required
							name="gdpr"
							value={formValues.gdpr}
							label={pageContext.CONTACT_FORM_GDPR}
							errorMessage={pageContext.CONTACT_FORM_GDPR_ERROR}
							onChange={handleGdprCheckbox}
							link={`/${pageContext.lang}/${pageContext.MENU.GDPR.URI}`}
						/>
					</div>
					<div className="contact_form-submit">
						<Button
							loading={loading}
							type="submit"
							name="submit"
							outerClass="form-input centered"
							buttonClass="green"
							label={pageContext.CONTACT_FORM_BUTTON}
						/>
					</div>
				</div>
			</Form>
		</StyledContactForm>
	);
}

ContactForm.propTypes = {
	pageContext: PropTypes.shape({
		CONTACT_FORM_HEADLINE: PropTypes.string.isRequired,
		CONTACT_FORM_NAME: PropTypes.string.isRequired,
		CONTACT_FORM_ERROR_NAME: PropTypes.string.isRequired,
		CONTACT_FORM_EMAIL: PropTypes.string,
		CONTACT_FORM_ERROR_EMAIL: PropTypes.string.isRequired,
		CONTACT_FORM_NOTE: PropTypes.string.isRequired,
		CONTACT_FORM_GDPR: PropTypes.string.isRequired,
		CONTACT_FORM_GDPR_ERROR: PropTypes.string.isRequired,
		CONTACT_FORM_BUTTON: PropTypes.string.isRequired,
		lang: PropTypes.string.isRequired
	}).isRequired,
	formValues: PropTypes.shape({
		gdpr: PropTypes.any,
		CONTACT_FORM_NAME: PropTypes.string,
		CONTACT_FORM_ERROR_NAME: PropTypes.string,
		message: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		CONTACT_FORM_EMAIL: PropTypes.string,
		CONTACT_FORM_ERROR_EMAIL: PropTypes.string,
		CONTACT_FORM_NOTE: PropTypes.string,
		CONTACT_FORM_GDPR: PropTypes.string,
		CONTACT_FORM_GDPR_ERROR: PropTypes.string,
		CONTACT_FORM_BUTTON: PropTypes.string
	}),
	onChangeForm: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

ContactForm.defaultProps = {
	formValues: PropTypes.shape({
		CONTACT_FORM_NAME: "",
		CONTACT_FORM_ERROR_NAME: "",
		CONTACT_FORM_ERROR_EMAIL: "",
		CONTACT_FORM_EMAIL: "",
		CONTACT_FORM_NOTE: "",
		CONTACT_FORM_GDPR: "",
		CONTACT_FORM_GDPR_ERROR: "",
		CONTACT_FORM_BUTTON: ""
	})
};

export default ContactForm;
