import requestWrapper from "../requestWrapper";

async function sendMessage(contact) {
	return requestWrapper({
		url: "sendEmail/",
		method: "POST",
		data: {
			...contact,
			subject: "Synetech web - contact form"
		}
	});
}

export default sendMessage;
