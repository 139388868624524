import React from "react";
import PropTypes from "prop-types";

const Message = ({ type, message }) => {
	return (
		<div className={`message-container ${type}`}>
			<div className={`message ${type}`}>{message}</div>
		</div>
	);
};

Message.propTypes = {
	type: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired
};

export default Message;
