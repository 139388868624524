import React from "react";
import PropTypes from "prop-types";

/*
 * Zatím neupravovat podle eslintu
 *	https://github.com/airbnb/javascript/issues/1885#issuecomment-413343258
 * Pro 100% pokrytí by se musela měnit html struktura a tím pádem revidovat CSS
 * not enough time
 */

const Checkbox = ({
	name,
	value,
	onChange,
	required,
	link,
	label,
	errorMessage
}) => {
	return (
		<div className="form-checkbox-input">
			<input
				type="checkbox"
				name={name}
				id={name}
				value={value}
				onChange={onChange}
				required={required}
				checked={value}
			/>
			<label htmlFor={name} className="checkmark" />
			<span
				style={{
					textAlign: "left",
					margin: 0,
					fontSize: "12px",
					position: "relative",
					top: "-5px",
					textDecoration: "none",
					color: "#2aad7c"
				}}
			>
				{link ? (
					<a
						href={link}
						target="blank"
						style={{
							color: "#2aad7c"
						}}
					>
						{label}
					</a>
				) : (
					label
				)}
			</span>
			<div className="error-message">{errorMessage}</div>
		</div>
	);
};

Checkbox.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	required: PropTypes.bool.isRequired,
	link: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	errorMessage: PropTypes.string.isRequired
};

Checkbox.defaultProps = {
	value: false
};

export default Checkbox;
