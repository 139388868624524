import React from "react";
import PropTypes from "prop-types";

/*
 * Zatím neupravovat podle eslintu
 *	https://github.com/airbnb/javascript/issues/1885#issuecomment-413343258
 * Pro 100% pokrytí by se musela měnit html struktura a tím pádem revidovat CSS
 * not enough time
 */

const Textarea = ({
	name,
	type,
	label,
	placeholder,
	required,
	minLength,
	maxLength,
	value,
	rows,
	onChange
}) => (
	<div className="form-input">
		<label htmlFor={name}>{label}</label>
		<textarea
			type={type}
			name={name}
			id={name}
			value={value}
			placeholder={placeholder}
			onChange={onChange}
			minLength={minLength}
			maxLength={maxLength}
			required={required ? "required" : ""}
			rows={rows}
		/>
		<div className="error-message" />
	</div>
);

Textarea.propTypes = {
	name: PropTypes.string.isRequired,
	type: PropTypes.string,
	label: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	required: PropTypes.bool.isRequired,
	minLength: PropTypes.number,
	maxLength: PropTypes.number,
	value: PropTypes.string,
	rows: PropTypes.number,
	onChange: PropTypes.func.isRequired
};

Textarea.defaultProps = {
	type: "text",
	minLength: 0,
	maxLength: 1000,
	value: "",
	rows: 5,
	placeholder: ""
};

export default Textarea;
