import styled from "styled-components";

const StyledContactForm = styled.div`
	.contact_form-section {
		margin: auto;
		text-align: center;
		width: 600px;
		display: grid;
		grid-template-columns: 50% 50%;
		grid-template-rows: auto auto auto auto auto;
		grid-row-gap: 30px;
		grid-column-gap: 30px;
		padding: 70px 0;
	}
	.contact_form-header {
		grid-column: 1/3;
		grid-row: 1/2;
	}
	.contact_form-header h2 {
		margin: 30px 0 30px 0;
	}
	.contact_form-name {
		grid-column: 1/2;
		grid-row: 2/3;
	}
	.contact_form-email {
		grid-column: 2/3;
		grid-row: 2/3;
	}
	.contact_form-note {
		grid-column: 1/3;
		grid-row: 3/4;
	}
	.contact_form-gdpr {
		grid-column: 1/3;
		grid-row: 4/5;
	}
	.contact_form-submit {
		grid-column: 1/3;
		grid-row: 5/6;
	}

	@media only screen and (min-width: 2350px) {
		.contact_form-section {
			width: 900px;
			margin: auto;
		}
	}

	@media only screen and (max-width: 1200px) {
		.contact_form-section {
			margin: auto;
			text-align: center;
			width: 100%;
			display: grid;
			grid-template-columns: auto;
			grid-template-rows: auto auto auto auto auto auto;
			grid-row-gap: 10px;
			padding: 50px 30px;
			box-sizing: border-box;
		}
		.contact_form-header {
			grid-column: 1/2;
			grid-row: 1/2;
		}
		.contact_form-name {
			grid-column: 1/2;
			grid-row: 2/3;
		}
		.contact_form-email {
			grid-column: 1/2;
			grid-row: 3/4;
		}
		.contact_form-note {
			grid-column: 1/2;
			grid-row: 4/5;
		}
		.contact_form-gdpr {
			grid-column: 1/2;
			grid-row: 5/6;
		}
		.contact_form-submit {
			grid-column: 1/2;
			grid-row: 6/7;
		}
	}
`;

export default StyledContactForm;
