import React from "react";
import PropTypes from "prop-types";

/*
 * Zatím neupravovat podle eslintu
 *	https://github.com/airbnb/javascript/issues/1885#issuecomment-413343258
 * Pro 100% pokrytí by se musela měnit html struktura a tím pádem revidovat CSS
 * not enough time
 */

const TextInput = ({
	name,
	type,
	label,
	placeholder,
	required,
	minLength,
	maxLenght,
	min,
	max,
	value,
	onChange,
	errorMessage,
	style
}) => (
	<div className="form-input" style={style}>
		<label htmlFor={name}>{label}</label>
		<input
			type={type}
			name={name}
			value={value}
			placeholder={placeholder}
			onChange={onChange}
			minLength={minLength}
			maxLength={maxLenght}
			min={min}
			max={max}
			required={required ? "required" : null}
		/>
		<div className="error-message">{errorMessage}</div>
	</div>
);

TextInput.propTypes = {
	name: PropTypes.string.isRequired,
	type: PropTypes.string,
	label: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	minLength: PropTypes.number,
	maxLenght: PropTypes.number,
	min: PropTypes.number,
	max: PropTypes.number,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	errorMessage: PropTypes.string,
	style: PropTypes.objectOf(PropTypes.string)
};

TextInput.defaultProps = {
	type: "text",
	minLength: 0,
	maxLenght: 128,
	min: null,
	max: null,
	value: "",
	placeholder: "",
	errorMessage: "Chyba",
	required: false,
	style: {}
};

export default TextInput;
