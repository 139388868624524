import styled from "styled-components";

const StyledContactInfo = styled.div`
	@media only screen and (min-width: 2350px) {
		padding: 50px;
		box-sizing: border-box;

		.contact_address .headline {
			font-size: 32px;
			margin-bottom: 20px;
		}
		.contact_address .content {
			font-size: 22px;
			line-height: 30px;
		}
		.contact_photo {
			height: 650px;
		}
	}

	width: 100%;
	display: grid;
	grid-template-columns: 40% 60%;
	grid-template-rows: auto auto;
	padding: 50px;
	box-sizing: border-box;

	.contact_address {
		grid-column: 1/2;
		grid-row: 1/2;
	}
	.contact_address .headline {
		margin-bottom: 20px;
	}
	.contact_address .content {
		line-height: 25px;
	}
	.contact_address .content .contact_address_map {
		margin-top: 20px;
	}
	.contact_address .content .contact_address_map a {
		color: #161616;
	}
	.contact_photo {
		grid-column: 2/3;
		grid-row: 1/2;
		width: 100%;
		height: 350px;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}
	.contact_media {
		grid-column: 1/2;
		grid-row: 2/3;
	}
	.contact_media .headline {
		margin-bottom: 20px;
	}
	@media only screen and (max-width: 1200px) {
		width: 100%;
		display: grid;
		grid-template-columns: auto;
		grid-template-rows: auto auto auto;
		grid-row-gap: 20px;
		padding: 50px 30px;
		box-sizing: border-box;

		.contact_address {
			grid-column: 1/2;
			grid-row: 2/3;
		}
		.contact_photo {
			grid-column: 1/2;
			grid-row: 1/2;
			width: 100%;
			height: 200px;
		}
		.contact_media {
			grid-column: 1/2;
			grid-row: 3/4;
		}
	}
`;

export default StyledContactInfo;
