import React from "react";
import PropTypes from "prop-types";
import Spinner from "../Spinner";

/*
 *	Button type nelze přesně validovat bez explicitního uvedení hodnoty, proto markup 2x
 */

const Button = ({
	outerClass,
	type,
	name,
	buttonClass,
	onClick,
	label,
	loading
}) => {
	const buttonType = {};
	buttonType.submit = (
		<div className={outerClass}>
			<button
				type="submit"
				name={name}
				className={buttonClass}
				onClick={onClick}
			>
				{loading && <Spinner />}
				{label}
			</button>
		</div>
	);
	buttonType.button = (
		<div className={outerClass}>
			<button
				type="button"
				name={name}
				className={buttonClass}
				onClick={onClick}
			>
				{loading && <Spinner />}
				{label}
			</button>
		</div>
	);
	return buttonType[type];
};

Button.propTypes = {
	outerClass: PropTypes.string.isRequired,
	type: PropTypes.oneOf(["submit", "button"]).isRequired,
	name: PropTypes.string.isRequired,
	buttonClass: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	label: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired
};

Button.defaultProps = {
	onClick() {}
};

export default Button;
