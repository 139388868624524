import styled from "styled-components";

const StyledForm = styled.div`
	.loader-container {
		position: relative;
		height: 16px;
		display: inline-block;
		margin-right: 5px;
	}
	.loader-container .loader {
		margin: auto;
		position: relative;
		top: 0;
		float: none;
		border: 6px solid #2aad7c;
		border-radius: 50%;
		border-top: 6px solid #55cba0;
		width: 10px;
		height: 10px;
		line-height: 16px;
		-webkit-animation: spin 1s linear infinite;
		animation: spin 1s linear infinite;
	}

	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	.form-input {
		margin: 0 10px 0 0;
		text-align: left;
	}
	.form-input.centered {
		text-align: center;
	}
	.form-input:after {
		content: "";
		display: table;
		clear: both;
	}
	.form-input .error-message {
		padding: 0 10px 0 10px;
		font-size: 8pt;
		color: red;
		height: 15px;
		display: none;
	}
	.form-input .error-message.invalid {
		display: inline-block;
	}
	.form-input label {
		margin: 0;
		padding: 10px;
		font-size: 12px;
	}
	.form-input input[type="text"],
	.form-input input[type="email"],
	.form-input textarea {
		padding: 10px;
		margin: 5px;
		border: 1px solid #2aad7c;
		width: 100%;
		box-sizing: border-box;
		border-radius: 5px;
	}
	.form-input input[type="text"]:focus,
	.form-input input[type="email"]:focus,
	.form-input textarea:focus {
		outline-width: 0;
	}
	.form-input input[type="text"].field-invalid,
	.form-input input[type="email"].field-invalid,
	.form-input textarea.field-invalid {
		border: 1px solid red;
	}
	.form-input input[type="text"].field-invalid:focus,
	.form-input input[type="email"].field-invalid:focus,
	.form-input textarea.field-invalid:focus {
		outline-width: 0;
	}
	.form-input input[type="text"].text-input-plus,
	.form-input input[type="email"].text-input-plus,
	.form-input textarea.text-input-plus {
		margin-bottom: 0;
	}
	.form-input button {
		padding: 10px;
		border: none;
		margin: auto;
		border-radius: 5px;
		min-width: 80px;
		line-height: 20px;
		vertical-align: middle;
	}
	.form-input button:hover {
		cursor: pointer;
	}
	.form-input button:focus {
		outline-width: 0;
	}
	.form-input button.green {
		background: #ffffff;
		border: 1px solid #2aad7c;
		color: #2aad7c;
		border: 1px solid #2aad7c;
	}
	.form-input button.green:hover {
		background: #2aad7c;
		color: #ffffff;
	}

	.form-checkbox-input {
		display: table;
		margin-left: 5px;
		position: relative;
		text-align: left;
		padding-left: 35px;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.form-checkbox-input input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
	.form-checkbox-input .checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #ffffff;
		border: 1px solid #2aad7c;
		border-radius: 3px;
	}
	.form-checkbox-input label {
		text-align: left;
		margin: 0;
		font-size: 12px;
		position: relative;
		top: -5px;
	}
	.form-checkbox-input label a {
		color: #2aad7c;
		text-decoration: underline;
	}
	.form-checkbox-input .error-message {
		padding: 0 10px 0 0;
		font-size: 8pt;
		color: red;
		display: none;
	}
	.form-checkbox-input .error-message.invalid {
		display: block;
	}

	.form-checkbox-input input:checked ~ .checkmark {
		background-color: #2aad7c;
	}

	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	.form-checkbox-input input:checked ~ .checkmark:after {
		display: block;
	}

	.form-checkbox-input .checkmark:after {
		left: 9px;
		top: 5px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	@media only screen and (min-width: 2350px) {
		.form-input label {
			font-size: 18px;
		}
		.form-input input[type="text"],
		.form-input input[type="email"],
		.form-input textarea {
			font-size: 18px;
		}
		.form-input input[type="text"],
		.form-input input[type="email"] {
			height: 60px;
		}
		.form-input button {
			min-width: 130px;
			padding: 20px;
			font-size: 22px;
		}
		.form-input button.green {
			background: #f0fffa;
		}
	}

	.message-container {
		width: 100%;
		position: fixed;
		box-sizing: border-box;
		opacity: 0.8;
		top: 100px;
	}
	.message-container .message {
		text-align: center;
		width: 300px;
		min-height: 80px;
		line-height: 80px;
		vertical-align: middle;
		padding: 20px;
		margin: auto;
		border-radius: 10px;
	}
	.message-container .message.success {
		background: #2aad7c;
		color: #ffffff;
	}
	.message-container .message.error {
		background: #ad0821;
		color: #ffffff;
	}
	@media only screen and (max-width: 1200px) {
		.message-container {
			width: 100%;
			position: fixed;
			box-sizing: border-box;
			min-height: 40px;
			line-height: 40px;
			padding: 10px 0;
			vertical-align: middle;
			opacity: 0.8;
			top: 80px;
		}
		.message-container.success {
			background: #2aad7c;
			color: #ffffff;
		}
		.message-container.error {
			background: #ad0821;
			color: #ffffff;
		}
		.message-container .message {
			text-align: center;
			width: 100%;
			min-height: 40px;
			line-height: 40px;
			padding: 0;
			background: none;
			border-radius: 0;
		}
	}
`;

export default StyledForm;
